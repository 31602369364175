<template>
  <div class="intervenant-list">
    <Toolbar :handle-add="addHandler" />

    <v-container grid-list-xl fluid>
      <v-layout row wrap>
        <v-flex sm12>
          <h1>Liste des Intervenants</h1>
        </v-flex>
        <v-flex lg12>
          <DataFilter :handle-filter="onSendFilter" :handle-reset="resetFilter">
            <IntervenantFilterForm
              ref="filterForm"
              v-model="filters"
              slot="filter"
            />
          </DataFilter>
          <br />
          <v-data-table
            v-model="selected"
            :headers="headers"
            :items="items"
            :items-per-page.sync="options.itemsPerPage"
            :loading="isLoading"
            :loading-text="$t('Loading...')"
            :options.sync="options"
            :server-items-length="totalItems"
            class="elevation-1"
            item-key="@id"
            show-select
            @update:options="onUpdateOptions"
            :footer-props="{
              'items-per-page-options': [15, 30, 50, 100],
            }"
          >
            <template slot="item.evenements" slot-scope="{ item }">
              <ul>
                <li v-for="_item in item['evenements']" :key="_item.id">
                  {{ _item.id }}
                </li>
              </ul>
            </template>
            <template slot="item.mediaObjectImage" slot-scope="{ item }" v-if="item.mediaObjectImage">
              <v-img v-if="isImageExtension(item.mediaObjectImage.contentUrl)" contain max-height="75px" max-width="10vw" :src="getFileUrl(item.mediaObjectImage.contentUrl)"/>
              <template v-else>{{ item.contentUrl }}</template>
            </template>
            <ActionCell
              slot="item.action"
              slot-scope="props"
              :handle-show="() => showHandler(props.item)"
              :handle-edit="() => editHandler(props.item)"
              :handle-delete="() => deleteHandler(props.item)"
            ></ActionCell>
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";
import ListMixin from "../../mixins/ListMixin";
import ActionCell from "../../components/ActionCell";
import IntervenantFilterForm from "../../components/intervenant/Filter";
import DataFilter from "../../components/DataFilter";
import Toolbar from "../../components/Toolbar";
import {isImageExtension} from '../../utils/filesType';
import {ENTRYPOINT} from "../../config/entrypoint";

export default {
  name: "IntervenantList",
  servicePrefix: "Intervenant",
  mixins: [ListMixin],
  components: {
    Toolbar,
    ActionCell,
    IntervenantFilterForm,
    DataFilter
  },
  data() {
    return {
      headers: [
        // { text: 'slug', value: 'slug' },
        { text: "Nom", value: "nom" },
        { text: "Type", value: "type[nom]" },
        { text: "Edition", value: "evenement[nom]" },
        { text: "Logo/Photo", value: "mediaObjectImage" },
        {
          text: "Actions",
          value: "action",
          sortable: false
        }
      ],
      selected: []
    };
  },
  computed: {
    ...mapGetters("intervenant", {
      items: "list"
    }),
    ...mapFields("intervenant", {
      deletedItem: "deleted",
      error: "error",
      isLoading: "isLoading",
      resetList: "resetList",
      totalItems: "totalItems",
      view: "view"
    })
  },
  methods: {
    ...mapActions("intervenant", {
      getPage: "fetchAll",
      deleteItem: "del"
    }),
    isImageExtension,
    getFileUrl(path){
      return ENTRYPOINT+path
    }
  }
};
</script>
