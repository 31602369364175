<template>
  <v-container fluid>
    <v-select
      v-model="event"
      :items="evenementsSelectItems"
      :no-data-text="$t('No results')"
      :label="$t('Evenement')"
      item-text="nom"
      item-value="id"
      chips
      close
      @click:close="chips = false"
    >
    </v-select>
     <v-select
      v-model="type"
      :items="intervenantTypeSelectItems"
      :no-data-text="$t('No results')"
      :label="$t('Type')"
      item-text="nom"
      item-value="slug"
      chips
      close
      @click:close="chips = false"
    >
     </v-select>
  </v-container>
</template>

<script>
import has from "lodash/has";
import isEmpty from "lodash/isEmpty";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "IntervenantFilter",
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      event: null,
      type: null,
    };
  },
  mounted() {
    this.evenementsGetSelectItems();
    this.intervenantTypeGetSelectItems();
  },
  watch: {
    'event': function() {
      this.$emit("input", { "evenement.id": this.event });
    },
    'type' : function() {
      this.$emit("input", {"type.slug": this.type} );
    },
    value: function() {
      if(isEmpty(this.value)){
        this.event=null;
        this.type=null;
      }
    }
  },
  computed: {
    ...mapFields("evenement", {
      evenementsSelectItems: "selectItems"
    }),
    ...mapFields("intervenantType", {
     intervenantTypeSelectItems: "selectItems"
    }),
    // eslint-disable-next-line
    item() {
      let data = this.initialValues || this.value;
      return data;
    }
  },
  methods: {
    ...mapActions({
      evenementsGetSelectItems: "evenement/fetchSelectItems"
    }),
     ...mapActions({
      intervenantTypeGetSelectItems: "intervenantType/fetchSelectItems"
    }),
  }
};
</script>
